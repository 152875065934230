import { PositionRelative } from 'cdk'
import {
  BORDER_RADIUS_2XS_VALUE,
  BORDER_WIDTH_1,
  COLOR_ERROR,
  COLOR_LIGHTER,
  COLOR_NEUTRAL_20,
  COLOR_NEUTRAL_40,
  COLOR_RED_20,
  COLOR_VIOLET_10,
  COLOR_VIOLET_50,
  TIME_300,
} from 'design-tokens'
import { ChangeEvent, FormEvent, forwardRef, InputHTMLAttributes, useCallback, useState } from 'react'
import styled from 'styled-components'

const Container = styled(PositionRelative).withConfig({ displayName: 'Checkbox' })`
  width: 16px;
  height: 16px;
  border-radius: ${BORDER_RADIUS_2XS_VALUE};
  color: ${COLOR_VIOLET_10};

  svg {
    position: absolute;
    inset: 0;
    vertical-align: top;
    pointer-events: none;

    path {
      fill: none;
      transition: stroke-dashoffset ${TIME_300} cubic-bezier(0.215, 0.61, 0.355, 1);
      stroke-width: 3;
      stroke-dasharray: 50;
      stroke-dashoffset: 50;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }

  &:has(:disabled) {
    color: ${COLOR_NEUTRAL_20};
  }

  &:has(:checked) {
    color: ${COLOR_LIGHTER};

    path {
      stroke-dashoffset: 35;
    }
  }
`

const Input = styled.input.withConfig({ displayName: 'CheckboxInput' })`
  position: absolute;
  inset: 0;
  margin: 0;
  border: ${BORDER_WIDTH_1} solid ${COLOR_VIOLET_50};
  border-radius: ${BORDER_RADIUS_2XS_VALUE};
  background-color: ${COLOR_LIGHTER};
  cursor: pointer;
  transition:
    background-color ${TIME_300} ease-out,
    border-color ${TIME_300} ease-out;
  appearance: none;

  &:hover {
    background-color: ${COLOR_VIOLET_10};
  }

  &:user-invalid {
    border-color: ${COLOR_ERROR};
  }

  &:disabled {
    border-color: ${COLOR_NEUTRAL_40};
    background-color: ${COLOR_NEUTRAL_20};
    cursor: not-allowed;
  }

  &:checked {
    background-color: ${COLOR_VIOLET_50};

    &:disabled {
      background-color: ${COLOR_NEUTRAL_40};
    }

    &:user-invalid {
      background-color: ${COLOR_ERROR};
    }
  }

  &:user-invalid:not(:checked):hover {
    background-color: ${COLOR_RED_20};
  }
`

const Sign = () => (
  <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="m7 12.5 3 3 7-7" stroke="currentColor" />
  </svg>
)

export type CheckboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  /** Use `name` for `aria-labelledby` @see https://www.w3.org/WAI/tutorials/forms/instructions/ */
  name?: string
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({ onChange, onInvalid, ...props }, ref) => {
  const [isInvalid, setIsInvalid] = useState(false)

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsInvalid(!event.target.checkValidity())

      if (onChange) {
        onChange(event)
      }
    },
    [onChange],
  )

  const handleOnInvalid = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      setIsInvalid(true)

      if (onInvalid) {
        onInvalid(event)
      }
    },
    [onInvalid],
  )

  return (
    <Container>
      <Input
        ref={ref}
        aria-checked={props.checked}
        aria-disabled={props.disabled}
        aria-invalid={isInvalid}
        type="checkbox"
        {...props}
        onChange={handleOnChange}
        onInvalid={handleOnInvalid}
      />
      <Sign />
    </Container>
  )
})
